<app-firefox-clipboard-item></app-firefox-clipboard-item>

<div class="sky-card">
  <div class="sky-card-header">
    <h2 class="h3 mb-0 title">{{ requesting ? 'Sky outfit request' : 'Sky closet' }}</h2>
    <h2 class="h3 mb-0 title-small">&nbsp;</h2>
    <div class="btns-top btns-top-closet" *ngIf="!requesting">
      <a class="button" [routerLink]="'/outfit-request/collage'" data-step="901" data-position="bottom-right-aligned">
        <mat-icon class="menu-icon">photo</mat-icon>
        <span class="menu-label">Collage</span>
      </a>
    </div>
    <div class="btns-top btn-top-request" *ngIf="requesting">
      <a class="button" [routerLink]="'/'" data-step="904" data-position="bottom-right-aligned">
        <mat-icon class="menu-icon">home</mat-icon>
        <span class="menu-label">Home</span>
      </a>
    </div>
  </div>
  <div class="sky-card-body">
    <div class="container">
      <div>
        First time? Try the
        <button type="button" (click)="startTour()" class="">
          <mat-icon class="menu-icon">explore</mat-icon>
          <span class="menu-label">Tutorial</span>
        </button> to learn more about this page!
      </div>
      <div class="mt" data-step="0" data-position="bottom-left-aligned">
        <ng-container *ngIf="requesting">
          Please visit the
          <a class="button" [routerLink]="'/outfit-request/closet'" *ngIf="requesting" data-step="0" data-position="bottom-right-aligned">
            <mat-icon class="menu-icon">checkroom</mat-icon>
            <span class="menu-label">Closet</span>
          </a>
          page if you want to customize and share your closet. The current page is only used to create outfit requests!
        </ng-container>
        <ng-container *ngIf="!requesting">
          Please visit the
          <a class="button" [routerLink]="'/outfit-request/request'" *ngIf="!requesting" data-step="0" data-position="bottom-right-aligned">
            <mat-icon class="menu-icon">add</mat-icon>
            <span class="menu-label">Request</span>
          </a>
          page if you want to create an outfit request.
        </ng-container>
      </div>
    </div>
    <div class="container mt" data-step="100" data-position="bottom-left-aligned">
      <div class="sky-flex flex-wrap">
        <!-- Display mode. -->
        <div class="button-group" *ngIf="!requesting" data-step="101">
          <button type="button" (click)="toggleCloset()">
            <mat-icon class="menu-icon">checkroom</mat-icon>
            <span class="menu-label">{{ closetMode === 'all' ? 'Show my items' : 'Show all items' }}</span>
          </button>
        </div>
        <!-- Modify-->
        <div class="button-group" *ngIf="!requesting" data-step="102" data-position="bottom-left-aligned">
          <button type="button" (click)="modifyCloset()" [class.highlight]="modifyingCloset">
            <mat-icon class="menu-icon">manufacturing</mat-icon>
            <span class="menu-label">Modify closet</span>
          </button>
        </div>
        <!-- Hide items not marked. -->
        <div class="button-group" *ngIf="!requesting" data-step="121" data-position="bottom-left-aligned">
          <button type="button" (click)="toggleHideUnselected()">
            <mat-icon class="menu-icon">filter_alt</mat-icon>
            <span class="menu-label">{{ hideUnselected ? 'Show all': 'Show selected' }}</span>
          </button>
        </div>
        <!-- Toggle item size -->
        <div class="button-group" data-step="122" data-position="bottom-left-aligned">
          <button type="button" (click)="toggleItemSize()">
            <mat-icon class="menu-icon">{{ itemSize === 'small' ? 'zoom_in' : 'zoom_out' }}</mat-icon>
            <span class="menu-label">{{ itemSize === 'small' ? 'Show large icons': 'Show small icons' }}</span>
          </button>
        </div>
        <!-- Hide items from IAPs. -->
        <button type="button" (click)="toggleIap()" *ngIf="!requesting" data-step="123" data-position="bottom-left-aligned">
          <mat-icon class="menu-icon">{{ hideIap ? 'attach_money' : 'money_off' }}</mat-icon>
          <span class="menu-no-label">{{ hideIap ? 'Show IAP' : 'Hide IAP' }}</span>
        </button>
        <!-- Change background -->
        <button type="button" class="btn-copy" [class.highlight]="showingBackgroundPicker" (click)="showBackgroundPicker($event)">
          <mat-icon class="menu-icon">wallpaper</mat-icon>
          <span class="menu-label">Background</span>
        </button>
        <div class="button-group">
          <!-- Reset -->
          <button type="button" class="button-danger" (click)="resetSelected()" data-step="124" data-position="bottom-left-aligned">
            <mat-icon class="menu-icon">delete_forever</mat-icon>
            <span class="menu-label">Reset</span>
          </button>
          <!-- Random -->
          <button type="button" class="button-danger" (click)="randomSelection()" data-step="125" data-position="bottom-left-aligned">
            <mat-icon class="menu-icon">shuffle</mat-icon>
            <span class="menu-no-label"></span>
          </button>
        </div>
        <button type="button" class="button" (click)="calculateCost()" data-step="126" data-position="bottom-left-aligned">
          <mat-icon class="menu-icon">calculate</mat-icon>
          <span class="menu-label">Calculate costs</span>
        </button>
      </div>
      <div class="mt">
        <div class="search" data-step="126" data-position="bottom-left-aligned">
          <input tabindex="0" #input class="search-input" type="search" (input)="search()" placeholder="Search for items">
          <mat-icon (click)="selectSearch()">search</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Background picker -->
@if (showingBackgroundPicker) {
  <div class="sky-card mt">
    <div class="sky-card-header">
      <h1 class="h2 mb-0">Background</h1>
    </div>
    <div class="sky-card-body">
      <div class="background-picker">
        @for (section of backgroundSections; let i = $index; track i) {
          <div class="container">
            <h4 class="mb-0 mt-0">{{ section.label }}</h4>
          </div>
          <div class="grid grid-4 mt">
            <img src="/assets/game/background/random.webp" class="background-picker-item" (click)="setBackground(section.guid)"/>
            <ng-container *ngFor="let bg of section.backgrounds">
              <img [src]="bg.url" class="background-picker-item" (click)="setBackground(bg.guid)"/>
            </ng-container>
          </div>
        }
      </div>
    </div>
  </div>
}

<!-- Modify closet -->
<div class="sky-card mt" *ngIf="modifyingCloset">
  <div class="sky-card-header">
    <h2 class="h3">Modify closet</h2>
  </div>
  <div class="sky-card-body">
    <div class="container" data-step="103" data-position="bottom-left-aligned">
      Show items in columns. It is recommended to set this number to match your in-game closet.
      <div class="mt">
        <div class="button-group">
          <button type="button" [class.btn-column-active]="columns === 3" (click)="setColumns(3)">3</button>
          <button type="button" [class.btn-column-active]="columns === 4" (click)="setColumns(4)">4</button>
          <button type="button" [class.btn-column-active]="columns === 5" (click)="setColumns(5)">5</button>
          <button type="button" [class.btn-column-active]="columns === 6" (click)="setColumns(6)">6</button>
          <button type="button" [class.btn-column-active]="columns === 7" (click)="setColumns(7)">7</button>
          <button type="button" [class.btn-column-active]="columns === 8" (click)="setColumns(8)">8</button>
        </div>
      </div>
    </div>
    <div class="container mt" data-step="104" data-position="bottom-left-aligned">
      <p class="mb-0">
        During seasons and events you can preview items in-game before you own them. Include these in the closet when showing your own items?
      </p>
      <div class="sky-flex flex-wrap mt">
        <button type="button" (click)="toggleOngoing()">
          <mat-icon class="menu-icon">{{ showOngoing ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
          <span class="menu-label">Include ongoing items</span>
        </button>
      </div>
    </div>
    <div class="container mt" data-step="105" data-position="bottom-left-aligned">
      <p class="mb-0">
        Because Sky Planner is primarily an item tracker you can use your tracked items to keep the closet updated.<br/>
        You can also manually choose what items to show and hide. Simply click on any item below to hide it from your closet.
      </p>
      <div class="sky-flex flex-wrap mt">
        <div class="button-group">
          <button type="button" (click)="toggleSync()">
            <mat-icon class="menu-icon">{{ shouldSync ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
            <span class="menu-label">Sync closet</span>
          </button>
          <button type="button" class="button-danger" (click)="resetSync()">Reset</button>
        </div>
        <button type="button" class="" (click)="modifyCloset()" data-step="106" data-position="bottom-right-aligned">
          <mat-icon class="menu-icon">done</mat-icon>
          <span class="menu-label">Done modifying</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Problematic items -->
<div #warnHidden class="sky-card mt" *ngIf="selectionHasUnavailable || (!requesting && selectionHasHidden)">
  <div class="sky-card-body">
    <div class="container border-warn" *ngIf="selectionHasUnavailable">
      <mat-icon class="menu-icon color-warn">priority_high</mat-icon>
      <span class="menu-label">This selection has items not available in the shared closet.</span>
    </div>
    <div class="container border-warn" *ngIf="!requesting && selectionHasHidden" [class.mt]="selectionHasUnavailable">
      <mat-icon class="menu-icon color-warn">priority_high</mat-icon>
      <span class="menu-label">This selection has items hidden in your closet. You might be missing some items.</span>
    </div>
  </div>
</div>

<!-- TS items -->
<app-card class="mt" *ngIf="ts" [foldable]="true" [folded]="true">
  <div header>
    <h4 class="h5 mb-0 ts-title">
      <app-spirit-type-icon [type]="'Season'" class="ts-icon"></app-spirit-type-icon>
      <span *ngIf="tsState === 'future'">Upcoming Traveling Spirit</span>
      <span *ngIf="tsState === 'active'">{{ ts.spirit.name }}</span>
    </h4>
  </div>
  <div class="sky-flex flex-wrap gap-half">
      <ng-container *ngFor="let item of tsItems">
        <div class="closet-item-container d-inline-block">
          <div class="closet-item" (click)="toggleItem(item)"
            [class.r]="selected.r[item.guid]"  [class.y]="selected.y[item.guid]" [class.g]="selected.g[item.guid]" [class.b]="selected.b[item.guid]"
            [class.highlight]="searchResults && searchResults[item.guid]"
          >
            <app-item-icon [item]="item" [size]="itemSize" [opaque]="true" [hoverGlow]="false" [lazy]="false"></app-item-icon>
          </div>
        </div>
      </ng-container>
    </div>
</app-card>

<!-- RS items -->
<app-card class="mt" *ngIf="rs" [foldable]="true" [folded]="true">
  <div header>
    <h4 class="h5 mb-0 ts-title">
      <app-spirit-type-icon [type]="'Season'" class="ts-icon"></app-spirit-type-icon>
      <span>{{ rs.name }}</span>
    </h4>
  </div>
  <div class="sky-flex flex-wrap gap-half">
    <ng-container *ngFor="let s of rsSpirits; let si = index;">
      <div class="sky-flex flex-cols flex-align-start gap-half">
        <div class="container pad-0 pl pr ws-nw">{{ s.returning.spirit.name }}</div>
        <div class="sky-flex flex-wrap gap-half">
          <ng-container *ngFor="let item of s.items">
            <div class="closet-item-container d-inline-block">
              <div class="closet-item" (click)="toggleItem(item)"
                [class.r]="selected.r[item.guid]"  [class.y]="selected.y[item.guid]" [class.g]="selected.g[item.guid]" [class.b]="selected.b[item.guid]"
                [class.highlight]="searchResults && searchResults[item.guid]"
              >
                <app-item-icon [item]="item" [size]="itemSize" [opaque]="true" [hoverGlow]="false" [lazy]="false"></app-item-icon>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</app-card>
<!-- Event items -->
<app-card class="mt" *ngFor="let event of events" [foldable]="true" [folded]="true">
  <div header>
    <h4 class="h5 mb-0">
      {{ event.instance.event.name }}
    </h4>
  </div>

  <div class="sky-flex flex-wrap gap-half">
    <div class="sky-flex flex-cols flex-align-start gap-half">
      <div class="container pad-0 pl pr ws-nw">In-game currency</div>
      <div class="sky-flex flex-wrap gap-half">
        <ng-container *ngFor="let item of event.items">
          <div class="closet-item-container d-inline-block">
            <div class="closet-item" (click)="toggleItem(item)"
              [class.r]="selected.r[item.guid]"  [class.y]="selected.y[item.guid]" [class.g]="selected.g[item.guid]" [class.b]="selected.b[item.guid]"
              [class.highlight]="searchResults && searchResults[item.guid]"
            >
              <app-item-icon [item]="item" [size]="itemSize" [opaque]="true" [hoverGlow]="false" [lazy]="false"></app-item-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="sky-flex flex-cols flex-align-start gap-half">
      <div class="container pad-0 pl pr ws-nw">In-App Purchase</div>
      <div class="sky-flex flex-wrap gap-half">
        <ng-container *ngFor="let item of event.iapItems">
          <div class="closet-item-container d-inline-block">
            <div class="closet-item" (click)="toggleItem(item)"
              [class.r]="selected.r[item.guid]"  [class.y]="selected.y[item.guid]" [class.g]="selected.g[item.guid]" [class.b]="selected.b[item.guid]"
              [class.highlight]="searchResults && searchResults[item.guid]"
            >
              <app-item-icon [item]="item" [size]="itemSize" [opaque]="true" [hoverGlow]="false" [lazy]="false"></app-item-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

</app-card>


<!-- Item list -->
<div class="sky-card mt" data-step="1" data-position="top-left-aligned">
  <div class="sky-card-header header-items header-sticky">
    <h2 class="h3 mb-0 label-items d-inline-block">Items</h2>&nbsp;
    <!-- Copy buttons -->
    <div class="btns-copy d-inline-block v-top" data-step="4" data-position="bottom-left-aligned">
      <div class="button-group">
        <!-- Copy image-->
        <button type="button" class="btn-copy btn-copy-image" (click)="showCopyImagePicker($event)" [disabled]="isRendering" data-step="6" data-position="bottom-left-aligned"
          ngbTooltip="Copied!" #ttCopyImg="ngbTooltip" triggers="manual" container="body" placement="bottom" [closeDelay]="1000" [autoClose]="true" tooltipClass="show">
          <mat-icon class="menu-icon" [class.spin]="isRendering === 2">{{ isRendering === 2 ? 'cached' : 'file_copy' }}</mat-icon>
          <span class="menu-label">Share</span>
        </button>
        <!-- Copy link -->
        <button type="button" class="btn-copy btn-copy-link" (click)="copyLink()" [disabled]="isRendering" data-step="5" data-position="bottom-left-aligned"
          ngbTooltip="Copied link!" #ttCopyLnk="ngbTooltip" triggers="manual" container="body" placement="bottom" [closeDelay]="1000" [autoClose]="true" tooltipClass="show">
          <mat-icon class="menu-icon" [class.spin]="isRendering === 1">{{ isRendering === 1 ? 'cached' : 'link' }}</mat-icon>
          <span class="menu-no-label"></span>
        </button>
      </div>
      <!-- Unavailable items -->
      <span class="warn-unavailable" *ngIf="selectionHasUnavailable || (!requesting && selectionHasHidden)" (click)="scrollToWarning()">
        <mat-icon>priority_high</mat-icon>
      </span>
      <!-- Copy image picker -->
      @if (showingImagePicker) {
        <div #divCopyImagePicker class="copy-image-picker">
          @if (!requesting) {
            <div class="button-group btn-group-img">
              <button type="button" class="btn-copy-img" (click)="shareImage('closet')" data-step="7" data-position="top-left-aligned">
                <mat-icon class="menu-icon">image</mat-icon>
                <span class="menu-label">Share closet</span>
              </button>
              <button type="button" class="" (click)="copyImage('closet')">
                <mat-icon class="menu-icon">file_copy</mat-icon>
                <span class="menu-no-label"></span>
              </button>
            </div>
          }
          <div class="button-group btn-group-img">
            <button type="button" class="btn-copy-img" (click)="shareImage('request')" data-step="8" data-position="top-left-aligned">
              <mat-icon class="menu-icon">background_grid_small</mat-icon>
              <span class="menu-label">Share request</span>
            </button>
            <button type="button" class="" (click)="copyImage('request')">
              <mat-icon class="menu-icon">file_copy</mat-icon>
              <span class="menu-no-label"></span>
            </button>
          </div>
          <div class="button-group btn-group-img">
            <button type="button" class="btn-copy-img" (click)="shareImage('square')" data-step="9" data-position="top-left-aligned">
              <mat-icon class="menu-icon">grid_on</mat-icon>
              <span class="menu-label">Share one fit</span>
            </button>
            <button type="button" class="" (click)="copyImage('square')">
              <mat-icon class="menu-icon">file_copy</mat-icon>
              <span class="menu-no-label"></span>
            </button>
          </div>
          @if (!requesting) {
            <div class="button-group btn-group-img">
              <button type="button" class="btn-copy-img" (click)="shareImage('template')" data-step="10" data-position="top-left-aligned">
                <mat-icon class="menu-icon">image</mat-icon>
                <span class="menu-label">Share template</span>
              </button>
              <button type="button" class="" (click)="copyImage('template')">
                <mat-icon class="menu-icon">file_copy</mat-icon>
                <span class="menu-no-label"></span>
              </button>
            </div>
          }
        </div>
      }
    </div>
    <!-- Selection colors -->
    <div class="color-picker" data-step="3" data-position="bottom-right-aligned">
      <div class="btn-color-active">
        <button type="button" class="btn-color btn-b-r" (click)="showColorPicker($event)" [class]="{ 'btn-b-r':color==='r', 'btn-b-y':color==='y', 'btn-b-g':color==='g', 'btn-b-b':color==='b'}"
          ngbTooltip="Change selection color" container="body" placement="top-right">
          &nbsp;<mat-icon>border_color</mat-icon>
        </button>
      </div>
      <div #divColorPicker class="btn-colors" [hidden]="!showingColorPicker">
        <button type="button" class="btn-color btn-r" (click)="setColor('r')"
          ngbTooltip="Mark items red" container="body" placement="top-right">
        </button>
        <button type="button" class="btn-color btn-y" (click)="setColor('y')"
          ngbTooltip="Mark items yellow" container="body" placement="top-right">
          &nbsp;
        </button>
        <button type="button" class="btn-color btn-g" (click)="setColor('g')"
          ngbTooltip="Mark items green" container="body" placement="top-right">
          &nbsp;
        </button>
        <button type="button" class="btn-color btn-b" (click)="setColor('b')"
          ngbTooltip="Mark items blue" container="body" placement="top-right">
          &nbsp;
        </button>
        <button type="button" class="btn-color" (click)="setColor(undefined)"
          ngbTooltip="Unmark items" container="body" placement="top-right">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- Closet item sections -->
  <div #divClosetContainer class="sky-card-body closet-container scroll-x" [attr.data-mode]="color" [attr.data-modifying]="modifyingCloset || undefined" [attr.data-size]="itemSize">
    <div class="closet-grid">
      <div class="closet-grid-section">
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'Outfit' }"></ng-container>
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'Shoes' }"></ng-container>
      </div>
      <div class="closet-grid-section-divider"></div>
      <div class="closet-grid-section">
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'Mask' }"></ng-container>
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'FaceAccessory' }"></ng-container>
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'Necklace' }"></ng-container>
      </div>
      <div class="closet-grid-section-divider"></div>
      <div class="closet-grid-section">
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'Hair' }"></ng-container>
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'HairAccessory' }"></ng-container>
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'HeadAccessory' }"></ng-container>
      </div>
      <div class="closet-grid-section-divider"></div>
      <div class="closet-grid-section">
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'Cape' }"></ng-container>
      </div>
      <div class="closet-grid-section-divider"></div>
      <div class="closet-grid-section">
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'Held' }"></ng-container>
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'Furniture' }"></ng-container>
        <ng-container [ngTemplateOutlet]="templateItemsType" [ngTemplateOutletContext]="{ type: 'Prop' }"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #templateItemsType let-type="type">
  <div class="closet-items" [attr.data-type]="type" [style.grid-template-columns]="'repeat(' + columns + ', ' + (itemSizePx+8) + 'px)'"
    [attr.data-step]="type === 'Outfit' ? 2 : undefined" data-position="top-left-aligned">
    <!-- Closet icon, foldable -->
    <div [style.gridColumn]="'1/' + (columns+1)" class="closet-type t-center" (click)="toggleClosetSection(type)">
      <mat-icon [svgIcon]="itemIcons[type]"></mat-icon>
      <div class="closet-type-fold">
        <mat-icon>{{ typeFolded[type] ? 'add' : 'remove' }}</mat-icon>
      </div>
    </div>
    <!-- Items -->
    <ng-container [ngTemplateOutlet]="templateItems" [ngTemplateOutletContext]="{ $implicit: items[type], type }">
    </ng-container>
  </div>
</ng-template>

<!-- Items -->
<ng-template #templateItems let-items let-type="type">
  @if (!requesting) {
    <!-- Closet display. -->
    @for (item of items; let i = $index; track item.guid) {
      <!-- When modifying closet, show all items regardless of other checks. -->
      <!-- Hide all items regardless of other checks if the closet is folded. -->
      <!-- When showing only selected items, hide all other items. -->
      <!-- Keep hidden items visible (darkened) when showing all items. Hide them in closet mode. -->
      <!-- If enabled, keep ongoing event items visible (darkened). -->
      <div class="closet-item-container" [hidden]="!modifyingCloset && (typeFolded[type] || (hideUnselected && !selected.all[item.guid]) || (!selected.all[item.guid] && closetMode === 'closet' && hidden[item.guid] && (!showOngoing || !ongoingItems[item.guid])))">
        <div class="closet-item" (click)="toggleItem(item)"
          [class.r]="selected.r[item.guid]"  [class.y]="selected.y[item.guid]" [class.g]="selected.g[item.guid]" [class.b]="selected.b[item.guid]"
          [class.closet-hide]="hidden[item.guid] || (!modifyingCloset && ((hideIap && item.iaps?.length) || (available && !available[item.guid])))" [attr.data-guid]="item.guid"
          [class.highlight]="searchResults && searchResults[item.guid]"
          [ngbTooltip]="templateItemHover" container="body"
        >
          <app-item-icon [item]="item" [size]="itemSize" [opaque]="true" [hoverGlow]="false" [lazy]="false"></app-item-icon>
          <div class="d-inline-block w-100 t-center" (click)="showDyePicker(item, $event)">
            @if (!item.dyeSlots) { <mat-icon class="dye-icon dye-icon-inactive">palette</mat-icon> }
            @if (item.dyeSlots > 0) { <mat-icon class="dye-icon dye-icon-active" [ngClass]="dyeClasses[item.guid]?.[0]">palette</mat-icon> }
            @if (item.dyeSlots > 1) { <mat-icon class="dye-icon dye-icon-active" [ngClass]="dyeClasses[item.guid]?.[1]">palette</mat-icon> }
          </div>
        </div>
      </div>

      <!-- Tooltip -->
      <ng-template #templateItemHover>
        @if (item) { <span class="ws-nw">{{item.name}}</span> }
      </ng-template>
    }
  } @else {
    <!-- Request display. -->
    @for (item of items; let i = $index; track item.guid) {
      <div class="closet-item-container" [hidden]="typeFolded[type]">
        <div class="closet-item" (click)="toggleItem(item)"
          [class.r]="selected.r[item.guid]" [class.y]="selected.y[item.guid]" [class.g]="selected.g[item.guid]" [class.b]="selected.b[item.guid]"
          [class.closet-hide]="(hideIap && item.iaps?.length) || (available && !available[item.guid])" [attr.data-guid]="item.guid"
          [class.highlight]="searchResults && searchResults[item.guid]"
          [ngbTooltip]="templateItemHover" container="body"
        >
          <app-item-icon [item]="item" [size]="itemSize" [opaque]="true" [hoverGlow]="false" [lazy]="false"></app-item-icon>
          <div class="d-inline-block w-100 t-center" (click)="showDyePicker(item, $event)">
            @if (!item.dyeSlots) { <mat-icon class="dye-icon dye-icon-inactive">palette</mat-icon> }
            @if (item.dyeSlots > 0) { <mat-icon class="dye-icon dye-icon-active" [ngClass]="dyeClasses[item.guid]?.[0]">palette</mat-icon> }
            @if (item.dyeSlots > 1) { <mat-icon class="dye-icon dye-icon-active" [ngClass]="dyeClasses[item.guid]?.[1]">palette</mat-icon> }
          </div>
        </div>
      </div>

      <!-- Tooltip -->
      <ng-template #templateItemHover>
        @if (item) { <span class="ws-nw">{{item.name}}</span> }
      </ng-template>
    }
  }
</ng-template>

@if (showingDyePicker && dyeItem) {
  <app-overlay [closeText]="'Close'" (close)="closeDyePicker()">
    <div #divDyePicker class="dye-picker t-center">
      <div class="">
        <app-item-icon [item]="dyeItem" [hoverGlow]="false" [opaque]="true" [size]="'default'"></app-item-icon>
      </div>
      @if (dyeItem.dyeSlots! > 0) {
        <div><b>Dye 1</b></div>
        <ng-container [ngTemplateOutlet]="tDyeGrid" [ngTemplateOutletContext]="{ i: 0 }"></ng-container>
      }
      @if (dyeItem.dyeSlots! > 1) {
        <div class="mt"><b>Dye 2</b></div>
        <ng-container [ngTemplateOutlet]="tDyeGrid" [ngTemplateOutletContext]="{ i: 1 }"></ng-container>
      }

      <ng-template #tDyeGrid let-i="i">
        @let dye = dyes[dyeItem.guid][i];
        <div class="c-accent">Primary</div>
        <div class="dye-grid">
          <div class="dye-grid-color dye-grid-blank" [class.highlight]="!dye.primary" (click)="selectDye(i, 'primary', undefined)"><mat-icon [svgIcon]="'none'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.primary==='red'" (click)="selectDye(i, 'primary', 'red')"><mat-icon class="dye-red" [svgIcon]="'dye-red'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.primary==='purple'" (click)="selectDye(i, 'primary', 'purple')"><mat-icon class="dye-purple" [svgIcon]="'dye-purple'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.primary==='blue'" (click)="selectDye(i, 'primary', 'blue')"><mat-icon class="dye-blue" [svgIcon]="'dye-blue'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.primary==='cyan'" (click)="selectDye(i, 'primary', 'cyan')"><mat-icon class="dye-cyan" [svgIcon]="'dye-cyan'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.primary==='green'" (click)="selectDye(i, 'primary', 'green')"><mat-icon class="dye-green" [svgIcon]="'dye-green'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.primary==='yellow'" (click)="selectDye(i, 'primary', 'yellow')"><mat-icon class="dye-yellow" [svgIcon]="'dye-yellow'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.primary==='black'" (click)="selectDye(i, 'primary', 'black')"><mat-icon class="dye-black" [svgIcon]="'dye-black'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.primary==='white'" (click)="selectDye(i, 'primary', 'white')"><mat-icon class="dye-white" [svgIcon]="'dye-white'"></mat-icon></div>
        </div>
        <div class="c-accent">Secondary</div>
        <div class="dye-grid">
          <div class="dye-grid-color dye-grid-blank" [class.highlight]="!dye.secondary" (click)="selectDye(i, 'secondary', undefined)"><mat-icon class="" [svgIcon]="'none'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.secondary==='red'" (click)="selectDye(i, 'secondary', 'red')"><mat-icon class="dye-red" [svgIcon]="'dye-red'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.secondary==='purple'" (click)="selectDye(i, 'secondary', 'purple')"><mat-icon class="dye-purple" [svgIcon]="'dye-purple'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.secondary==='blue'" (click)="selectDye(i, 'secondary', 'blue')"><mat-icon class="dye-blue" [svgIcon]="'dye-blue'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.secondary==='cyan'" (click)="selectDye(i, 'secondary', 'cyan')"><mat-icon class="dye-cyan" [svgIcon]="'dye-cyan'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.secondary==='green'" (click)="selectDye(i, 'secondary', 'green')"><mat-icon class="dye-green" [svgIcon]="'dye-green'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.secondary==='yellow'" (click)="selectDye(i, 'secondary', 'yellow')"><mat-icon class="dye-yellow" [svgIcon]="'dye-yellow'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.secondary==='black'" (click)="selectDye(i, 'secondary', 'black')"><mat-icon class="dye-black" [svgIcon]="'dye-black'"></mat-icon></div>
          <div class="dye-grid-color" [class.highlight]="dye.secondary==='white'" (click)="selectDye(i, 'secondary', 'white')"><mat-icon class="dye-white" [svgIcon]="'dye-white'"></mat-icon></div>
        </div>
      </ng-template>
    </div>

  </app-overlay>
}
